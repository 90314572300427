import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { fetchRecommendedProdList, addRecommendedProd } from '../store/actions/services';
import { fetchProducts } from '../store/actions/product';
import { colors } from '@mui/material';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme } from '@mui/material/styles';

export default function AddRecommendedProductPage(props) {

    const [values, setValues] = useState({
        // Id: '',
        ProductId: '',
        Image: '',
        Status: '',
        StartDate: '',
        EndDate: '',
    });

    console.log("start date", values.StartDate);

    const [errorMsg, setErrorMsg] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isPRFetching = useSelector(state => (state.product.isPRFetching || false));
    const products = useSelector(state => (state.product.products || []));

    const mount = () => {
        dispatch(fetchProducts({}));
    }
    useEffect(mount, []);

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
        setErrorMsg(null);
    };

    const handlechangedropdown = (selected, state) => {
        setValues({ ...values, [state]: selected !== null ? selected.value : '' });
        setErrorMsg(null);
    }


    const isInputValid = () => {
        let isInputValid = true;
        let errorMsg = "";

        if (values.ProductId === "") {
            isInputValid = false;
            errorMsg = "Please Select Product.";
        } else if (values.Image === "") {
            isInputValid = false;
            errorMsg = "Please Select Product's Image.";
        }
        setErrorMsg(errorMsg);

        return isInputValid;
    }

    const hiddenFileInput = React.useRef(null);

    const handleUploadImgBtn = () => {
        //method to Link FileInput element
        hiddenFileInput.current.click();
    };

    const handleAddImg = (event) => {

        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            setValues({ ...values, Image: e.target.result });
        }
    };

    const handleSubmit = () => {
        if (isInputValid()) {
            var AddRecomProdParams = {
                // Id: values.Id,
                ProductId: values.ProductId,
                Image: values.Image,
                Status: values.Status,
                StartDate: format(values.StartDate.$d, "yyyy-MM-dd HH:mm:ss"),
                EndDate: format(values.EndDate.$d, "yyyy-MM-dd HH:mm:ss"),

                // EndDate: format(values.EndDate.$d, "dd/MM/yyyy HH:mm:ss"),
                // StartDate: format(values.StartDate, "dd/MM/yyyy"),
                // EndDate: format(values.EndDate, "dd/MM/yyyy"),
            };

            // console.log("submit params");
            // console.log(AddRecomProdParams);
            dispatch(addRecommendedProd(AddRecomProdParams)).then(r => dispatch(fetchRecommendedProdList({})));
            navigate("/recommendedProducts");
        
        }
    }




    const StatusArray = [
        { label: "Active", value: "Active" },
        { label: "In-Active", value: "InActive" },
    ];

    const productsArray = products.map((p, i) => {
        return ({
            // key: i,
            label: p.BrandName + " - " + p.Name + " (" + p.Size + ") ",
            value: p.Id
        })
    });


    const newTheme = (theme) => createTheme({
        ...theme,
        components: {
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: '#1565c0',
                        // borderRadius: '7px',
                        borderWidth: '1px',
                        borderColor: '#2196f3',
                        // border: '1px solid',
                        backgroundColor: '#fff',
                    },
                    today: {
                        color: '#1565c0',
                        borderRadius: '7px',
                        borderWidth: '1px',
                        borderColor: '#2196f3',
                        border: '1px solid',
                        backgroundColor: '#fff',
                    },
                },
            },
        },
    });



    return (
        <div>
            <Card>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CardHeader title="Add Recommended Products" />
                    <div
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
                    >
                        <Button variant='contained' color="error" onClick={() => { navigate("/recommendedProducts") }}>Cancel</Button>
                    </div>
                </div>
                <Divider />
                <CardContent>
                    <div>
                        <Card>
                            <CardContent sx={{ p: 5 }} style={{ minHeight: 500 }}>
                                <Grid container spacing={3}>
                                    <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                                        <Typography variant="h5" >Recommanded Product's Image</Typography>
                                        <Typography variant="subtitle1" >Image should be (512 x 512px)</Typography>

                                        <div className='imageuploadbox'>

                                            {(values.Image) && (
                                                <div style={{ position: "relative", padding: 5, }}>
                                                    <IconButton
                                                        style={{ position: "absolute", right: 2, top: 2 }}
                                                        sx={{ backgroundColor: "white" }}
                                                        onClick={() => setValues({ ...values, Image: '' })}
                                                    >
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                    <img src={values.Image} width={210} height={125} style={{ alignSelf: "center", borderRadius: 15, objectFit: "contain" }} />
                                                </div>
                                            )}
                                        </div>
                                        {(!values.Image) && (
                                            <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                                                Upload Image
                                            </Button>
                                        )}
                                        <input ref={hiddenFileInput} style={{ display: 'none' }} id="RecomProdImgFInput" className="choosefile-input" type="file" onChange={handleAddImg} />

                                    </Grid>

                                    <Grid item xs={6} >
                                        <FormControl required sx={{ width: "100%" }}>
                                            <Select
                                                className="basic-single"
                                                placeholder={"Select Product"}
                                                styles={{
                                                    placeholder: (base) => ({
                                                        ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                                                    }),
                                                    /* For multivalues */
                                                    valueContainer: (base) => ({
                                                        ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                                                    }),

                                                    singleValue: (base) => ({
                                                        ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                                                    }),
                                                    menuList: (base) => ({
                                                        ...base, height: 150
                                                    }),
                                                }}
                                                isLoading={isPRFetching}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(selected) => handlechangedropdown(selected, "ProductId")}
                                                options={productsArray}
                                            />
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={6} >
                                        <FormControl required sx={{ width: "100%" }}>
                                            <Select
                                                className="basic-single"
                                                placeholder={"Status"}
                                                styles={{
                                                    placeholder: (base) => ({
                                                        ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                                                    }),
                                                    /* For multivalues */
                                                    valueContainer: (base) => ({
                                                        ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                                                    }),

                                                    singleValue: (base) => ({
                                                        ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                                                    }),
                                                }}
                                                value={StatusArray.filter(s => s.value === values.Status)}
                                                // isLoading={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(selected) => handlechangedropdown(selected, "Status")}
                                                options={StatusArray}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={2} spacing={4}>
                                        <div className="div-infocontainer" style={{ marginTop: 15 }}>
                                            <Typography variant="subtitle2">Start Date: </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} spacing={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ThemeProvider theme={newTheme}>
                                                <DatePicker
                                                    label="Select Start Date"
                                                    inputFormat="DD/MM/YYYY" value={values.StartDate}
                                                    onChange={(newValue) => {
                                                        console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                                                        setValues({ ...values, StartDate: newValue })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </ThemeProvider>
                                        </LocalizationProvider>

                                    </Grid>

                                    <Grid item xs={2} spacing={4}>
                                        <div className="div-infocontainer" style={{ marginTop: 15 }}>
                                            <Typography variant="subtitle2">End Date: </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} spacing={4}>
                                        {/* <DatePicker
                                            required
                                            selected={values.EndDate}
                                            showIcon
                                              dateFormat="dd/MM/yyyy"
                                            popperClassName="date-popper"
                                            onChange={(date) => setValues({ ...values, EndDate: date })}
                                            className="dt-picker-grid4"
                                            name='EndDate'
                                            // showTimeSelect
                                            // timeFormat="HH:mm"
                                            // timeIntervals={60}
                                            placeholderText="Select End Date"
                                        /> */}
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ThemeProvider theme={newTheme}>
                                                <DatePicker
                                                    label="Select End Date"
                                                    inputFormat="DD/MM/YYYY" 
                                                    value={values.EndDate}
                                                    onChange={(newValue) => {
                                                        console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                                                        setValues({ ...values, EndDate: newValue })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </ThemeProvider>
                                        </LocalizationProvider>


                                    </Grid>














                                </Grid>
                            </CardContent>

                            <Divider />

                            <div style={{ textAlign: "center", padding: 16 }}>
                                {(errorMsg && errorMsg !== "") && (
                                    <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                                )}
                                <Button variant='contained' color='secondary' onClick={handleSubmit} >Add</Button>
                            </div>
                        </Card>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}