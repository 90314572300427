import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
// import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
/* import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; */
// import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { addRecommendedProd, fetchRecommendedProdList, deleteRecommendedProdImage } from '../store/actions/services';
import { fetchProducts } from '../store/actions/product';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs'




export default function EditRecommendedProductPage(props) {

  const [values, setValues] = useState({
    Id: '',
    ProductId: '',
    Name: '',
    Image: '',
    Status: '',
    StartDate: '',
    EndDate: '',
  });

  const [errorMsg, setErrorMsg] = useState(null);
  const [dialogOpen, setdialog] = useState(false);



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isPRFetching = useSelector(state => (state.product.isPRFetching || false));
  const products = useSelector(state => (state.product.products || []));


  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }


  // eslint-disable-next-line
  // useLayoutEffect(mount, []);

  useEffect(() => {
    dispatch(fetchProducts({}));

    if (location.state && location.state.Recomproddetail) {
      setValues({
        Id: (location.state.Recomproddetail && location.state.Recomproddetail.Id) || "",
        ProductId: (location.state.Recomproddetail && location.state.Recomproddetail.ProductId) || "",
        Name: (location.state.Recomproddetail && location.state.Recomproddetail.Name) || "",
        Image: (location.state.Recomproddetail && location.state.Recomproddetail.Image) || "",
        Status: (location.state.Recomproddetail && location.state.Recomproddetail.Status) || "",
        StartDate: (location.state.Recomproddetail && location.state.Recomproddetail.StartDate) ? dayjs(location.state.Recomproddetail.StartDate) : '',
        EndDate: (location.state.Recomproddetail && location.state.Recomproddetail.StartDate) ? dayjs(location.state.Recomproddetail.EndDate) : ''
      });

    }
  }, [location]);

  console.log("locatoin", values)

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if (values.Name === "") {
      isInputValid = false;
      errorMsg = "Please Enter Product Name.";
    } else if (values.Image === "") {
      isInputValid = false;
      errorMsg = "Please Select product's Image.";
    }
    setErrorMsg(errorMsg);

    return isInputValid;
  }

  const hiddenFileInput = React.useRef(null);


  const handleUploadImgBtn = () => {
    //method to Link FileInput element
    hiddenFileInput.current.click();
  };

  const handleAddImg = (event) => {

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setValues({ ...values, Image: e.target.result });
    }
  };

  const handleDeleteimg = (clientId) => {
    dispatch(deleteRecommendedProdImage(clientId)).then(r => dispatch(fetchRecommendedProdList()));
    setValues({ ...values, Image: "" });
    setdialog(false);
  };

  const handleSubmit = () => {
    if (isInputValid()) {
    var UpdatedRecProdParams;
    if (document.getElementById("RecomProdImgFInput").files.length === 0) {
      UpdatedRecProdParams = {
        Id: values.Id,
        ProductId: values.ProductId,
        Status: values.Status,
        StartDate: format(values.StartDate.$d, "yyyy-MM-dd HH:mm:ss"),
        EndDate: format(values.EndDate.$d, "yyyy-MM-dd HH:mm:ss"),
      };

      dispatch(addRecommendedProd(UpdatedRecProdParams)).then(r => dispatch(fetchRecommendedProdList({})));
      navigate("/recommendedProducts");
    }
    else {
      UpdatedRecProdParams = {
        Id: values.Id,
        ProductId: values.ProductId,
        Image: values.Image,
        Status: values.Status,
        StartDate: format(values.StartDate.$d, "yyyy-MM-dd HH:mm:ss"),
        EndDate: format(values.EndDate.$d, "yyyy-MM-dd HH:mm:ss"),
      };
      
      dispatch(addRecommendedProd(UpdatedRecProdParams)).then(r => dispatch(fetchRecommendedProdList({})));
      navigate("/recommendedProducts");
    }
    }
  }

  const StatusArray = [
    { label: "Active", value: "Active" },
    { label: "In-Active", value: "InActive" },
  ];

  const productsArray = products.map((p, i) => {
    return ({
      label: p.BrandName + " - " + p.Name + " (" + p.Size + ") ",
      value: p.Id
    })
  });

  const newTheme = (theme) => createTheme({
    ...theme,
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: '#1565c0',
            borderWidth: '1px',
            borderColor: '#2196f3',
            backgroundColor: '#fff',
          },
          today: {
            color: '#1565c0',
            borderRadius: '7px',
            borderWidth: '1px',
            borderColor: '#2196f3',
            border: '1px solid',
            backgroundColor: '#fff',
          },
        },
      },
    },
  });

  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Recommended Products" />
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/recommendedProducts") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{ p: 5 }}>
                <Grid container spacing={3}>
                  <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                    <Typography variant="h5" >Recommanded Product Image</Typography>
                    <Typography variant="subtitle1" >Image should be (512 x 512px)</Typography>

                    <div className='imageuploadbox'>

                      {(values.Image) && (
                        <div style={{ position: "relative", padding: 5, }}>
                          <IconButton
                            style={{ position: "absolute", right: 2, top: 2 }}
                            sx={{ backgroundColor: "white" }}
                            onClick={() => setdialog(true)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                          <img src={values.Image} width={210} height={125} style={{ alignSelf: "center", borderRadius: 15, objectFit: "contain" }} />
                        </div>
                      )}
                    </div>
                    {(!values.Image) && (
                      <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                        Upload Image
                      </Button>
                    )}
                    <input ref={hiddenFileInput} style={{ display: 'none' }} id="RecomProdImgFInput" className="choosefile-input" type="file" onChange={handleAddImg} />

                    <Dialog open={dialogOpen} onClose={() => setdialog(false)} >
                      <DialogTitle >
                        {"Delete Image"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText >
                          Are you sure you wish to delete this client Image ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDeleteimg(values.Id)}>Yes</Button>
                        <Button onClick={() => setdialog(false)} autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select Product"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                          menuList: (base) => ({
                            ...base, height: 150
                          }),
                        }}
                        value={productsArray.filter(s => s.value === values.ProductId)}
                        isLoading={isPRFetching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "ProductId")}
                        options={productsArray}
                      />
                    </FormControl>
                  </Grid>





                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Status"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value={StatusArray.filter(s => s.value === values.Status)}
                        // isLoading={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "Status")}
                        options={StatusArray}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} spacing={4}>
                    <div className="div-infocontainer" style={{ marginTop: 15 }}>
                      <Typography variant="subtitle2">Start Date: </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} spacing={4}>
                    {/* <DatePicker
                                            required
                                            selected={values.StartDate}
                                            showIcon
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            popperClassName="date-popper"
                                            onChange={(date) => setValues({ ...values, StartDate: date })}
                                            className="dt-picker-grid4"
                                            name='StartDate'
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={60}
                                            placeholderText="Select Start Date"
                                        /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={newTheme}>
                        <DatePicker
                          label="Select Start Date"
                          inputFormat="DD/MM/YYYY"
                          value={values.StartDate}
                          onChange={(newValue) => {
                            console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                            // console.log("new value", new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(newValue),);
                            //   DeliveryDate: new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(newValue),

                            setValues({ ...values, StartDate: newValue })
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={2} spacing={4}>
                    <div className="div-infocontainer" style={{ marginTop: 15 }}>
                      <Typography variant="subtitle2">End Date: </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} spacing={4}>
                    {/* <DatePicker
                                            required
                                            selected={values.EndDate}
                                            showIcon
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            popperClassName="date-popper"
                                            onChange={(date) => setValues({ ...values, EndDate: date })}
                                            className="dt-picker-grid4"
                                            name='EndDate'
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={60}
                                            placeholderText="Select End Date"
                                        /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={newTheme}>
                        <DatePicker
                          label="Select End Date"
                          inputFormat="DD/MM/YYYY"
                          value={values.EndDate}
                          onChange={(newValue) => {
                            console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                            // console.log("new value", new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(newValue),);
                            //   DeliveryDate: new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(newValue),

                            setValues({ ...values, EndDate: newValue })
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </Grid>


                </Grid>
              </CardContent>

              <Divider />

              <div style={{ textAlign: "center", padding: 16 }}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Update</Button>
              </div>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}