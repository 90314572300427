import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { getFormatedCurrency, getFormatedDate, RupeeSymbol } from '../components/util';
import { format, parseISO } from 'date-fns';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs'

import { fetchOrderItemDetail, fetchOrderItemStatus, AddOrderItem, deleteOrderItem } from '../store/actions/quotation';
import { fetchVendors } from '../store/actions/vendor';

export default function EditOrderItemPage(props) {

  const [values, setValues] = useState({

    Id: '',
    Status: '',
    SendPushNotification: false,
    showcomment: false,
    OrderItemDetail: { Product: {}, DeliveryOrPickupAddress: {} },
    dateandtime: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useLocation();
  const [deleteOrderItemId, setdeleteOrderItemId] = useState("");
  const [dialogOpen, setdialog] = useState(false);
  const orderItemDetail = useSelector(state => (state.quotation.orderItemDetail || []));
  const orderItemStatus = useSelector(state => (state.quotation.orderItemStatus || []));
  const vendorList = useSelector(state => (state.vendor.vendors || []));


  // const handleChange = (event) => {
  //   setValues({...values, [event.target.name]: event.target.value});
  //   setErrorMsg(null);
  // };

  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if (values.OrderItemDetail.QuotationPrice === "" || values.OrderItemDetail.VendorPrice === "" || values.OrderItemDetail.VendorName === "" ||
      values.OrderItemDetail.OrderQuantity < 1 || values.OrderItemDetail.DeliveryDateTime === "" || values.OrderItemDetail.Status === "" ||
      values.OrderItemDetail.Discount === "" || values.OrderItemDetail.ServiceCharge === "" || values.OrderItemDetail.DeliveryCharge === "" ||
      values.OrderItemDetail.Transportation === "" || values.OrderItemDetail.AddressName === "") {
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
    }
    setErrorMsg(errorMsg);

    return isInputValid;
  }

  const handleItemsValueChange = (event) => {
    var odr = values.OrderItemDetail;
    // console.log("before oder");
    // console.log(JSON.stringify(odr));
    odr[event.target.name] = event.target.value;
    // console.log("updated values of oder");
    // console.log(JSON.stringify(odr));
    setValues({ ...values, OrderItemDetail: odr });
    setErrorMsg(null);
  };

  const handleItemsAddressChange = (fieldname, event) => {

    var odr = values.OrderItemDetail;

    odr[event.target.name] = event.target.value;

    if (fieldname === "Transportation") {
      odr.DeliveryDateTime = null;
      odr.PickupDateTime = null;
      odr.DeliveryAddressId = 0;
      odr.PickupAddressId = 0;
      odr.DeliveryOrPickupAddress.AddressId = 0;
      odr.DeliveryOrPickupAddress.AddressName = "";
      odr.DeliveryOrPickupAddress.Address = "";
      odr.DeliveryOrPickupAddress.Landmark = "";
      odr.DeliveryOrPickupAddress.Area = "";
      odr.DeliveryOrPickupAddress.City = "";
      odr.DeliveryOrPickupAddress.State = "";
      odr.DeliveryOrPickupAddress.Pincode = "";
    }
    var Sltd_Add = {};
    if (fieldname === "Delivery") {
      Sltd_Add = Object.assign({}, ...odr.CustomerAddressBook.filter(a => { if (a.AddressId === odr.DeliveryAddressId) return a; }))
    }
    if (fieldname === "PickUp") {
      Sltd_Add = Object.assign({}, ...odr.PickupAddress.filter(a => { if (a.AddressId === odr.PickupAddressId) return a; }))
    }

    if (Sltd_Add && Sltd_Add.AddressId) {
      odr.DeliveryOrPickupAddress.AddressId = Sltd_Add.AddressId;
      odr.DeliveryOrPickupAddress.AddressName = Sltd_Add.AddressName;
      odr.DeliveryOrPickupAddress.Address = Sltd_Add.Address;
      odr.DeliveryOrPickupAddress.Landmark = Sltd_Add.LandMark;
      odr.DeliveryOrPickupAddress.Area = Sltd_Add.Area;
      odr.DeliveryOrPickupAddress.City = Sltd_Add.City;
      odr.DeliveryOrPickupAddress.State = Sltd_Add.State;
      odr.DeliveryOrPickupAddress.Pincode = Sltd_Add.Pincode;
    }

    setValues({ ...values, OrderItemDetail: odr });
    setErrorMsg(null);
  };

  const handleDateChange = (dt) => {
    console.log("dateeeeeee", dt)
    var odr = values.OrderItemDetail;

    // console.log("before oder  dt");
    // console.log(JSON.stringify(odr));

    if (odr && odr.Transportation === "No") {
      odr.PickupDateTime = dt;
    }
    else if (odr) {
      odr.DeliveryDateTime = format(dt.$d, "yyyy-MM-dd HH:mm:ss");
    }
    // console.log("updated values of oder  dt");
    // console.log(JSON.stringify(odr));
    setValues({ ...values, OrderItemDetail: odr });
    setErrorMsg(null);
  };

  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };
  const deliverytime = [
    { label: 'Today', value: 'Today' },
    { label: 'Tomorrow', value: 'Tomorrow' },
    { label: 'Within 1 week', value: '1 week' },
    { label: 'Within 15 days', value: '15 days' },
  ];
  const transportation = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  const mount = () => {
    dispatch(fetchOrderItemDetail(location.state.OrderId, location.state.QuotationItemId));
    dispatch(fetchOrderItemStatus());
    dispatch(fetchVendors());
    dispatch(AddOrderItem());
    dispatch(deleteOrderItem());
  }
  useLayoutEffect(mount, []);


  useEffect(() => {

    //All this info is there in quotDetail

    // console.log("order detail updated");
    setValues({
      ...values,
      OrderItemDetail: { ...orderItemDetail }

    });
    // setStartDate(orderItemDetail.QuotationItemId);

  }, [orderItemDetail]);

  const handleItemDeleteClick = (id) => {
    dispatch(deleteOrderItem(id));
    setdialog(false);
    navigate("/orders");
  };

  const handleSaveOrderItem = () => {
    // if(isInputValid()){
    dispatch(AddOrderItem(values.OrderItemDetail));
    navigate("/editOrder", { state: { orders: values.OrderItemDetail } });
          console.log("updated values for submit");
          console.log(values.OrderItemDetail);
    // }
  }

  const newTheme = (theme) => createTheme({
    ...theme,
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: '#1565c0',
            // borderRadius: '7px',
            borderWidth: '1px',
            borderColor: '#2196f3',
            // border: '1px solid',
            backgroundColor: '#fff',
          },
          today: {
            color: '#1565c0',
            borderRadius: '7px',
            borderWidth: '1px',
            borderColor: '#2196f3',
            border: '1px solid',
            backgroundColor: '#fff',
          },
        },
      },
    },
  });


  const Prod = values.OrderItemDetail.Product;
  const DeliveryAddress = values.OrderItemDetail.DeliveryOrPickupAddress;
  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Order Item" />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}>
            <Button variant='contained' color="error" onClick={() => { navigate("/editOrder", { state: { orders: { OrderId: values.OrderItemDetail.OrderId } } }) }}>Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Grid container>
            <div style={{ borderRadius: 5, backgroundColor: "#D9D9D9", width: "100%", marginRight: 3, paddingInline: 15 }}>
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ fontSize: 22, fontWeight: "bold", marginLeft: -10 }}>
                  <CardHeader title="Order Item Detail" />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  {/* {!values.showproduct ?
                    <ExpandMoreIcon style={{height:25, width:25}}/>
                    : <ExpandLessIcon style={{height:25, width:25}}/>
                  } */}
                  <ExpandLessIcon style={{ height: 25, width: 25 }} />
                </div>
              </div>

              {/* {values.showproduct ? */}
              <div>

                <Card style={{ width: "100%", backgroundColor: "white", marginBottom: 15, borderRadius: 5, }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* <CardHeader title={o.Product.BrandName + " - " + o.Product.ProductName} /> */}
                    <CardHeader title={(Prod) ? Prod.BrandName + " - " + Prod.ProductName + " (" + Prod.ProductSize + ") "
                      : ""} />
                    <div
                      style={{
                        display: "flex",
                        // flexDirection:"row", 
                        alignItems: "center",
                        // justifyContent:"center", 
                        marginLeft: "auto",
                        marginRight: 18
                      }} >
                      <Button variant='contained' color="error" onClick={() => { setdeleteOrderItemId(values.OrderItemDetail.QuotationItemId); setdialog(true); }}>Delete</Button>
                      <div>
                        <Dialog open={dialogOpen} style={{ opacity: "1" }} onClose={() => setdialog(false)} >
                          <DialogTitle >
                            {"Delete Product"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText >
                              Are you sure you wish to delete this Item from Order ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => handleItemDeleteClick(deleteOrderItemId)}>Yes</Button>
                            <Button onClick={() => setdialog(false)} autoFocus>
                              No
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                  <Divider />

                  <div style={{ padding: 20 }}>
                    <Grid container spacing={2} display="flex">
                      <Grid item xs={3} container >
                        <img /* src={o.ProductImage} */ src={Prod ? Prod.ProductImage : null} alt={"Product img"} width="100%" height="100%" style={{ borderRadius: 5, borderStyle: 'outset', borderWidth: 1, borderColor: "#C4C4C4", }} />
                      </Grid>

                      <Grid item xs={9} spacing={2} container style={{ marginTop: 5 }}>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: true,
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Quotation Price" type="number" value={values.OrderItemDetail.QuotationPrice} name="QuotationPrice"/*  onChange={handleChange} */ onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Our Price" type="number" value={values.OrderItemDetail.OrderItemPrice} name="OrderItemPrice" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Vendor Price" type="number" value={values.OrderItemDetail.VendorPrice} name="VendorPrice" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Vendor Name</InputLabel>
                            <Select
                              InputProps={{
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              // disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values && values.OrderItemDetail && values.OrderItemDetail.VendorId ? values.OrderItemDetail.VendorId : ""}
                              label="Vendor Name"
                              name='VendorId'
                              onChange={(e) => handleItemsValueChange(e)}
                              MenuProps={MenuProps}
                            >
                              <MenuItem value={null}> <em>None</em> </MenuItem>
                              {vendorList.map((v, i) => {
                                return <MenuItem value={v.Id} key={i}>{v.FullName}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Pending Quantity" value={values.OrderItemDetail.Quantity - values.OrderItemDetail.DispatchQuantity} name="Quantity" />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            // disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                            fullWidth label="Process Quantity" type="number" value={values && values.OrderItemDetail && values.OrderItemDetail.OrderQuantity ? values.OrderItemDetail.OrderQuantity : ""} name="OrderQuantity" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        {/* <Grid item xs={4}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Expected Delivery Time</InputLabel>
                                  <Select
                                    InputProps={{
                                      readOnly : (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                                    }}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={values && values.OrderItemDetail && values.OrderItemDetail.DeliveryTime ? values.OrderItemDetail.DeliveryTime : ""}
                                    label="Expected DeliveryTime"
                                    name='DeliveryTime'
                                    onChange={ (e) => handleItemsValueChange(e)}
                                    MenuProps={MenuProps}
                                  >
                                    {deliverytime.map((d, i) => {
                                      return <MenuItem value={d.value} key={i}>{d.label}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              
                            </Grid> */}
                        <Grid item xs={4}>
                          {/* <DatePicker  
                                selected={ (values && values.OrderItemDetail && values.OrderItemDetail.Transportation === "No" && values.OrderItemDetail.PickupDateTime) ? values.OrderItemDetail.PickupDateTime : ((values && values.OrderItemDetail && values.OrderItemDetail.DeliveryDateTime) ? values.OrderItemDetail.DeliveryDateTime : null)}
                                showIcon
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                popperClassName="date-popper"
                                onChange={handleDateChange}
                                className="dt-picker"
                                name='dateandtime'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                placeholderText="Expected Delivery Time"
                              /> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={newTheme}>
                              <DatePicker
                                label="Expected Delivery Time"
                                inputFormat="DD/MM/YYYY"

                                value={ (values && values.OrderItemDetail && values.OrderItemDetail.Transportation === "No" && values.OrderItemDetail.PickupDateTime) ? values.OrderItemDetail.PickupDateTime : ((values && values.OrderItemDetail && values.OrderItemDetail.DeliveryDateTime) ? values.OrderItemDetail.DeliveryDateTime : null)}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                            <Select
                              InputProps={{
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values.OrderItemDetail.Status}
                              label="Status"
                              name='Status'
                              // onChange={handleSelectChange("Status")}
                              onChange={(e) => handleItemsValueChange(e)}
                              MenuProps={MenuProps}
                            >
                              {orderItemStatus.map((s, i) => {
                                return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Discount" type="number" value={values.OrderItemDetail.Discount} name="Discount" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>

                      </Grid>

                      <Grid item xs={12} spacing={2} container>

                        <Grid item xs={3}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Service Charge" type="number" value={values.OrderItemDetail.ServiceCharge} name="ServiceCharge" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Delivery Charges" type="number" value={values.OrderItemDetail.DeliveryCharge} name="DeliveryCharge" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Transportation</InputLabel>
                            <Select
                              InputProps={{
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values && values.OrderItemDetail && values.OrderItemDetail.Transportation ? values.OrderItemDetail.Transportation : ""}
                              label="Transportation"
                              name='Transportation'
                              onChange={(e) => handleItemsAddressChange("Transportation", e)}
                              MenuProps={MenuProps}
                            >
                              {transportation.map((t, i) => {
                                return <MenuItem value={t.value} key={i}>{t.label}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        {(values.OrderItemDetail.Transportation === "Yes") ?
                          <Grid item xs={3}>
                            <FormControl required sx={{ width: "100%" }}>
                              <InputLabel id="demo-controlled-open-select-label">Delivery Address</InputLabel>
                              <Select
                                InputProps={{
                                  readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                                }}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={values && values.OrderItemDetail && values.OrderItemDetail.DeliveryAddressId ? values.OrderItemDetail.DeliveryAddressId : ""}
                                label="Delivery Address"
                                name='DeliveryAddressId'
                                onChange={(e) => handleItemsAddressChange("Delivery", e)}
                                MenuProps={MenuProps}
                              >
                                {(values && values.OrderItemDetail && values.OrderItemDetail.CustomerAddressBook || []).map((a, i) => {
                                  return <MenuItem value={a.AddressId} key={i}>{a.AddressName}</MenuItem >
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          :
                          <Grid item xs={3}>
                            <FormControl required sx={{ width: "100%" }}>
                              <InputLabel id="demo-controlled-open-select-label">PickUp Address</InputLabel>
                              <Select
                                InputProps={{
                                  readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                                }}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={values && values.OrderItemDetail && values.OrderItemDetail.PickupAddressId ? values.OrderItemDetail.PickupAddressId : ""}
                                label="PickUp Address"
                                name='PickupAddressId'
                                onChange={(e) => handleItemsAddressChange("PickUp", e)}
                                MenuProps={MenuProps}
                              >
                                {(values && values.OrderItemDetail && values.OrderItemDetail.PickupAddress || []).map((p, i) => {
                                  return <MenuItem value={p.AddressId} key={i}>{p.AddressName}</MenuItem >
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        }
                        {/* </Grid> */}

                        {/* <Grid item xs={12} spacing={2} container> */}
                        {/* <Grid item xs={3}>
                              <TextField 
                                required
                                // disabled 
                                fullWidth label="Transportation" value={values.OrderItemDetail.Transportation} name="Transportation" />
                            </Grid> */}



                        <Grid item xs={3}>
                          <TextField
                            // required
                            // disabled 
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Address" value={DeliveryAddress && DeliveryAddress.Address ? DeliveryAddress.Address : ""} name="Address" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required 
                            // disabled
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Area" value={DeliveryAddress && DeliveryAddress.Area ? DeliveryAddress.Area : ""} name="Area" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required 
                            // disabled
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="City & State" value={DeliveryAddress ? DeliveryAddress.City + ", " + DeliveryAddress.State : ""} name="City" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required
                            // disabled 
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Pin Code" type="number" value={DeliveryAddress && DeliveryAddress.Pincode ? DeliveryAddress.Pincode : ""} />
                        </Grid>
                        {(values.OrderItemDetail.Transportation === "Yes") ?
                          // <div>
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              fullWidth label="Driver Name" value={values.OrderItemDetail.DriverName} name="DriverName" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                          : null
                        }
                        {(values.OrderItemDetail.Transportation === "Yes") ?
                          // <div>
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                // maxLength: 10,
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                              }}
                              fullWidth label="Driver Contact Number" type="number" value={values.OrderItemDetail.DriverContact} name="DriverContact" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                          : null
                        }
                        {(values.OrderItemDetail.Transportation === "Yes") ?
                          // <div>
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              fullWidth label="Vehicle Number" value={values.OrderItemDetail.VehicleNumber} name="VehicleNumber" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                          : null
                        }
                      </Grid>
                    </Grid>

                  </div>
                </Card>


              </div>
              {/* : null  */}
              {/* } */}
            </div>

          </Grid>
        </CardContent>
        <Divider />

        {(errorMsg && errorMsg !== "") && (
          <Typography mb={2} variant="body2" color='error' style={{ textAlign: "center", paddingTop: 16 }} >{errorMsg}</Typography>
        )}
        <div style={{ display: "flex", padding: 16, flexDirection: "row", justifyContent: "space-between" }}>
          <Button variant='contained' color='secondary' onClick={() => { navigate("/editOrder", { state: { orders: { Id: values.OrderItemDetail.OrderId } } }) }}>Back</Button>
          <Button variant='contained' color='secondary' onClick={handleSaveOrderItem}>Update Order Item</Button>
          <Button variant='contained' color='secondary' /* onClick={handleOrderGenerate} */>Generate Delivery challan</Button>
        </div>
      </Card>
    </div>
  );
}